.itemsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-bottom: 50px;

}

@media screen and (max-width: 1200px) {
    .itemsGrid {
        grid-template-columns: repeat(3, 1fr);
    }
  }

@media screen and (max-width: 892px) {
    .itemsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 576px) {
    .itemsGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.itemsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
@media screen and (max-width: 604px) {
    .itemsHeader {
        flex-direction: column;
    }
}

.sort {
    height: 80px;
    display: flex;
    align-items: center;
    
}

.category {
    margin-left: 50px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    background-color: #2FB5FC;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 25vh;
}
@media screen and (max-width: 502px) {
    .sort {
        flex-direction: column;
        justify-content: center;
        margin-top: 25px;
        
    }
    .category {
        margin: 20px 0 20px 0;
    }
    .itemsHeader {
        margin-bottom: 20px;
    }
}



.category:hover {
    opacity: 0.9;
}

.category:active {
    opacity: 0.6;
}


.createItemBtn {
    background: linear-gradient(180deg, #ffffff 0%, #D9DFFF 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: #000000;
    padding: 8px 12px   ;
}


.createItemBtn:hover {
    background: rgba(0, 0, 0, 0);
    color: #ffffff;
}

.createItemBtn:active {
    background: rgba(0, 0, 0, 0);
    color: #ffffff8d;
}


.itemsHeader {
    transform: translateX(-20px);
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimationDown;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;
}


@keyframes fadeInAnimationDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}