.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  
.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    transition: 0.4s all;
    font-size: 24px;
}

.modal_content.active {
    transform: translate(-50%, -50%) scale(1);

}

.modal_actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.btn {
    font-size: 24px;
}
