.createItem {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.form {
    background: rgba(50, 61, 109, 0.5);
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25);

    border-radius: 20px;
    padding: 30px;
    width: 50vh;
}
@media screen and (max-width: 576px) {
    .form{
        width: 45vh;
    }

}
.title {
    font-size: 40px;
    text-align: center;

}

.buttonContainer {
    padding: 20px 0;
}

.backButtonContainer{
    width: 50vh;

}

.buttons {
    align-items: center;
}

.createItem {
    transform: scale(0);
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-name: UnwrapAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;

}

.error{
    color: #FFBAB3;
    font-size: 15px;
    margin-top: -20px;
    padding-bottom: 20px;
}


@keyframes UnwrapAnimation {
    from {
        transform: scale(0);

    }

    to {
        transform: scale(1);


    }
}