.button {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    background-color: #2FB5FC;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;

}

.backButton {
    font-size: 14px;
    font-weight: bold;
    color: rgb(148, 163, 184);
    cursor: pointer;
}

.button:hover {
    opacity: 0.9;

}

.button:active {
    opacity: 0.6;
}



.backButton:hover {
    opacity: 0.9;

}

.backButton:active {
    opacity: 0.6;

}