.header {
    height: 35vh;
    position: relative;
    display: flex;
    align-items: top;
    background: linear-gradient(180deg, #191a43 0% , #442d85 100%);
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.title{
    font-size: 60px;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;
}

.desc {
    font-size: 30px;
    opacity: 0;
    transform: translateY(20px);
    animation-duration: 1s;
    animation-delay: 1s;
    animation-name: fadeInAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    color: rgba(255, 255, 255, 0.7);
    white-space: pre-line;
    text-transform: capitalize;
}

@media screen and (max-width: 992px) {

    .title{
        font-size: 40px;
    }
    .content {
        padding: 40px 15px 0px;
        text-align: center;
    }
}

@media screen and (max-width: 683px) {
    
    .content {
        padding: 30px 15px 0px;
        text-align: center;
    }
    .desc {
        padding: 10px;
        font-size: 25px;
    }
}

@media screen and (max-width: 576px) {
    .content {
        padding: 50px 15px 0px;

    }

    .title{
        padding: 0;
        font-size: 25px;
    }
    .desc {
        padding: 0;
        font-size: 18px;
        text-align: center;
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.wave {
    position: absolute;
    width: 100%;
    bottom: -200px;     
}

@media screen and (max-width: 992px) {
    .wave {
        bottom: -130px;
    }
}

@media screen and (max-width: 822px) {
    .wave {
        bottom: -100px;
    }
}

@media screen and (max-width: 768px) {
    .wave {
        width: 150%;
        bottom: -150px;
    }
}

@media screen and (max-width: 698px) {
    .wave {
        width: 180%;
        bottom: -160px;
    }
}

@media screen and (max-width: 576px) {
    .wave {
        width: 200%;
        bottom: -90px;
    }
}



/* for the pen */


/* waves */
.ocean {
  height: 80px; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.waveOcean {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23442d85'/%3E%3C/svg%3E");
  position: absolute;
  width: 300%;
  height: 100%;
  animation: wave 50s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.4;
  z-index: 1000;
}

.waveOcean:nth-of-type(2) {
  bottom: 0;
  animation: wave 58s linear reverse infinite;
  opacity: 0.3;
}

.waveOcean:nth-of-type(3) {
  bottom: 0;
  animation: wave 60s -1s linear infinite;
  opacity: 0.3;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(-50%);}
}

.logOut {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    padding-right: 40px;
}