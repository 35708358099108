.item {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    min-height: 40vh;
}

.title {
    margin-top: 40px;
}

.category{
    margin-top: 20px;
}

.buyBtnContainer {
    margin-bottom: 30px;
    display: flext;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.exterior {
    line-height: 24px;
    color: rgb(148, 163, 184);
}

.price {
    margin: 30px 0;
    font-size: 24px;
    font-weight: bold;
    color: #2FB5FC;
}

.imageContent{
    padding-left: 50px;
    align-self: center;

    transform: translateY(20px);
    opacity: 0;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimationRight;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;
}

.image {
    aspect-ratio: 3 / 2;
    object-fit: cover;
    border-radius: 20px;
    max-width: 600px;
    min-width: 350px;

}

.deleteBtn {
    border: 2px solid #cf1b1b;
    background: rgba(0, 0, 0, 0);
    color: #cf1b1b;
    transition: .1s;
}

@media screen and (max-width: 792px) {
    .item{
        flex-direction: column-reverse;
    }
    .descContent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .descContent > * {
        margin: 0;
    }
}


@media screen and (max-width: 576px) {
    .descContent {
        flex-direction: column;

    }
    .item{
        margin-bottom: 20px;
    }

    .image {
        min-width: 250px;
    }

    .contentDeleteBtn{
        margin-bottom: 20px;
    }

}
.nav{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

}



.deleteBtn:hover {
    background: #e91d1d;
    color: #ffffff;
}

.nav {
    transform: translateY(-20px);
    opacity: 0;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimationDown;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;
}


.contentDeleteBtn {
    transform: translateX(-20px);
    opacity: 0;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimationLeft;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.descContent {
    transform: translateX(-20px);
    opacity: 0;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-name: fadeInAnimationLeft;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    hyphens: manual;
}


@keyframes fadeInAnimationDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes fadeInAnimationLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInAnimationRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}